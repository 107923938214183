import React from "react";
import Layout from "../../components/Layout";
import { Link } from "gatsby";

class Travel extends React.Component {
  render() {
    return (
      <Layout title="Travel">
        <section className="section">
          <div className="container">
            <h3 className="subtitle is-2">Hotel Reservations</h3>
            <p className="mb-4">
              We’ve reserved a block of rooms at the Courtyard by Marriott and
              the Residence Inn downtown at{" "}
              <a href="https://goo.gl/maps/xvFKWXQFZqKQsGJV7" target="_blank">
                300 East 4th Street
              </a>
              . Reservations are cancelable until 48 hours prior to arrival.
            </p>
            <div className="mb-6">
              <a
                className="button is-info"
                href="https://book.passkey.com/go/frankforsanderwedding"
                target="_blank"
                rel="noopener noreferrer"
              >
                Make a Reservation
              </a>
            </div>

            <h3 className="subtitle is-2">Getting to/from the airport</h3>
            <h4 className="subtitle is-4">Bus</h4>
            <p className="mb-2">
              The Austin CapMetro route 20 bus makes frequent trips to/from the
              airport and downtown. The fare is $1.25 (you'll need cash or the
              CapMetro app). The bus stops at Lavaca and 4th street, which is
              just a few blocks away from the Courtyard and Residence Inn!
            </p>
            <p className="mb-2">
              At the airport, the bus is easy to find. The bus stop is shaped
              like a giant neon guitar!
            </p>
            <p className="mb-5">
              For more details, visit{" "}
              <a href="https://www.capmetro.org/airport">
                https://www.capmetro.org/airport
              </a>
              .
            </p>
            <h4 className="subtitle is-4">Lyft/Uber</h4>
            <p className="mb-2">
              It's generally very easy to find a Lyft or Uber at the Austin
              airport. Follow the signs in the airport for "Ride App Pickup".
              Visit{" "}
              <a href="https://www.austintexas.gov/department/ground-transportation-barbara-jordan-terminal#Rideshare">
                this link
              </a>{" "}
              for more information and detailed videos on how to navigate to the
              Ride App Pickup area.
            </p>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Travel;
